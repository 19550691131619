@layer components {
  .heading {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: theme('colors.fontColor');
  }

  h1,
  .h1 {
    @extend .heading;
    font-size: 38px;
    line-height: 40px;
  }

  h2,
  .h2 {
    @extend .heading;
    font-size: 30px;
    line-height: 34px;
  }

  h3,
  .h3 {
    @extend .heading;
    font-size: 24px;
    line-height: 28px;
  }

  h4,
  .h4 {
    @extend .heading;
    font-size: 20px;
    line-height: 24px;
  }

  h5,
  .h5 {
    @extend .heading;
    font-size: 16px;
    line-height: 22px;
  }

  .text-base {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  .text-base-light {
    @extend .text-base;
    font-weight: 300 !important;
  }

  .text-base-medium {
    @extend .text-base;
    font-weight: 600 !important;
  }

  .text-base-bold {
    @extend .text-base;
    font-weight: 700 !important;
  }

  .text-sm {
    @extend .text-base;
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .text-sm-light {
    @extend .text-sm;
    font-weight: 300 !important;
  }

  .text-sm-medium {
    @extend .text-sm;
    font-weight: 600 !important;
  }

  .text-sm-bold {
    @extend .text-sm;
    font-weight: 700 !important;
  }

  .text-xs {
    @extend .text-base;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .text-xs-light {
    @extend .text-xs;
    font-weight: 300 !important;
  }

  .text-xs-medium {
    @extend .text-xs;
    font-weight: 600 !important;
  }

  .text-xs-bold {
    @extend .text-xs;
    font-weight: 700 !important;
  }

  .link {
    text-decoration: underline;
    transition: all 200ms ease-in-out;

    @media (hover) {
      &:hover {
        color: theme('colors.linkHover');
      }
    }

    &:active {
      color: theme('colors.fontColor');
    }
  }

  .link-dark {
    @extend .link;

    @media (hover) {
      &:hover {
        color: #699cc9;
      }
    }

    &:active {
      color: #c3c8cb;
    }
  }

  code {
    word-wrap: break-word;
  }
}
