:root {
  --white: #ffffff;
  --black: #000000;
  --success: #10b981;
  --info: #009bcc;
  --warning: #fbbf24;
  --danger: #f43f5e;
  --btn-icon-hover: #699cc9;
  --link-hover: #23527c;

  --encom-50: #f0f2f2;
  --encom-100: #e1e4e5;
  --encom-200: #c3c8cb;
  --encom-300: #a5acb1;
  --encom-400: #879097;
  --encom-500: #69747d;
  --encom-600: #4b5863;
  --encom-700: #3c4a56;
  --encom-800: #2d3c49;
  --encom-900: #0f202f;

  --font-base: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
