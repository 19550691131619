.theme1 {
    --white: #FFFFFF;
    --black: #000000;
    --success: #10b981;
    --info: #3b82f6;
    --warning: #fbbf24;
    --danger: #f43f5e;
    --btn-icon-hover: #699CC9;
    --link-hover: #23527C;

    --encom-100: #34A6FC;
    --encom-200: #0C94FC;
    --encom-300: #037EDB;
    --encom-400: #0367B3;
    --encom-500: #02508B;
    --encom-600: #013054;
    --encom-700: #00101C;
    --encom-800: #000000;
    --encom-900: #000000;
}
