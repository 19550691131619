@tailwind components;

@layer components {
  .floating-label-group {
    display: flex;
    flex-flow: column-reverse;
    position: relative;

    label,
    input {
      transition: all 200ms ease-in-out;
      touch-action: manipulation;
    }

    input {
      &:placeholder-shown + label {
        height: 0;
        cursor: text;
        transform-origin: left bottom;
        transform: translate(16px, 16px);
      }

      &::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
      }

      &:focus {
        outline: 0 none;

        &::-webkit-input-placeholder {
          opacity: 1;
        }
      }

      &:not(:placeholder-shown) + label,
      &:focus + label {
        height: 0;
        font-size: 9px;
        transform: translate(16px, 0);
        pointer-events: none;
      }

      &:disabled {
        background-color: theme('colors.gray100');
        cursor: not-allowed;

        + label {
          color: theme('colors.gray400');
          cursor: not-allowed;
        }
      }
    }

    .info-group {
      $layerZIndex: 100;

      &-btn {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        z-index: $layerZIndex + 1;
        cursor: pointer;
      }

      &-content {
        position: absolute;
        width: 200px;
        bottom: 100%;
        right: 0;
        transform: translateX(50%);
        font-size: 13px;
        z-index: $layerZIndex + 1;
        background-color: theme('colors.white');
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }

      &-layer {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $layerZIndex;
      }
    }
  }

  .input-radio-group {
    input {
      width: 16px;
      height: 16px;
      transition: all 200ms ease-in-out;
      cursor: pointer;
      margin-right: 8px;

      &:checked {
        background-color: theme('colors.primary');
        background-image: none;
        border: 3px solid theme('colors.gray50');
        outline: 1px solid theme('colors.gray200');

        &:hover,
        &:focus {
          background-color: theme('colors.primary');
          border: 3px solid theme('colors.gray50');
          outline: 1px solid theme('colors.primary');
          outline-offset: 0;
          box-shadow: none;
        }
      }

      &:disabled {
        background-color: theme('colors.gray200');
        border-color: theme('colors.gray200');
        outline-color: theme('colors.gray200');
        cursor: not-allowed;

        + label {
          cursor: not-allowed;
        }
      }
    }

    label {
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .input-checkbox-group {
    input {
      width: 16px;
      height: 16px;
      transition: border 200ms ease-in-out;
      cursor: pointer;
      margin-right: 8px;

      &:checked {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACISURBVHgBtdDRDUAwEAbgOwNghE6AVUzASEZgAjGBmEBHMIINzrVFpKK0if+p6d3X9goQGPRpTkWREtCollEAKghw+HTjFSFgty6yxhCk9iNV8EUacmFORD7bB7iQhgg0mYFpPPAbUkHTmLX8UxUvJTeWjHoXOqGF98IzuiUWWcPzEj+1hb+yAXODSJq4FOkKAAAAAElFTkSuQmCC');
        background-size: 60% 65%;
        background-color: theme('colors.gray50');
        border: 1px solid theme('colors.gray200');

        &:hover,
        &:focus {
          background-color: theme('colors.gray50');
          border: 1px solid theme('colors.primary');
          outline: 0 none;
          outline-offset: 0;
        }
      }

      &:disabled {
        background-color: theme('colors.gray200');
        border-color: theme('colors.gray200');
        cursor: not-allowed;

        + label {
          cursor: not-allowed;
        }
      }
    }

    label {
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
